const RoadMapItem = ({ id,stage, details, isEven, img }) => {
  
  const expandTournament=(e)=>{
    if(e.target.parentNode.className=="roadmap-item roadmap-item--even"){
      e.target.style.width="70%";
      e.target.style.marginLeft="-20rem";
    }
    if(e.target.parentNode.className=="roadmap-item roadmap-item--odd"){
      e.target.style.width="70%";
      e.target.style.marginRight="-20rem";
    }
  }
  
  const shrinkTournament=(e)=>{
    if(e.target.parentNode.className=="roadmap-item roadmap-item--even"){
      e.target.style.marginLeft="-10rem";
      e.target.style.width="60%";

    }
    if(e.target.parentNode.className=="roadmap-item roadmap-item--odd"){
      e.target.style.marginRight="-15rem";
      e.target.style.width="60%";

    }

    }

  const selectTournament=(e)=>{
    window.location.href = "/IPoreiaMou/tournaments/"+e.currentTarget.id;
  }
  return (
    <>
      <div
        className={`roadmap-item ${
          isEven ? 'roadmap-item--even' : 'roadmap-item--odd'
        }`}
      >
        <div className="roadmap-item__marker"></div>
        <div className="roadmap__line"></div>
        <div className="roadmap-item__content" id={id} onMouseEnter={expandTournament} onMouseLeave={shrinkTournament} onClick={selectTournament}>
          {img!=""?<>
            <div className="roadmap-item__separator">
              <h3 className="roadmap-item__stage">{stage}</h3>
              <div className="roadmap-item__detail">
                {details.map((detail, index) => (
                  <li key={index} className="roadmap-item__detail">
                    {detail}
                  </li>
                ))}
              </div>
            </div>
           <img src={`/images/${img}.jpg`} alt={img} />
           </>:
           <>
           <div class="d-flex flex-column  " style={{width:"100%"}}>
            <h3 className="roadmap-item__stage">{stage}</h3>
            <div className="roadmap-item__detail" >
                  {details.map((detail, index) => (
                    <li key={index} className="roadmap-item__detail" >
                      {detail}
                    </li>
                  ))}
                </div>
            </div>
           </>}
        </div> 
      </div>
</>
  );
};

export default RoadMapItem;
