import React from "react";
import {
  StackedCarousel,
  ResponsiveContainer
} from "react-stacked-center-carousel";
import Fab from "@mui/material/Fab";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./Slide.css";
import { Slide } from "./Slide";

const data = [
  {
    image: "/images/Panellhnio2023Diploma.jpg",
    text: "Πανελλήνιο Πρωτάθλημα 2023",
    id:"Panellhnio2023"
  },
  {
    image: "/images/Panellhnio2022.jpg",
    text: "Πανελλήνιο Πρωτάθλημα 2022",
    id:"Panellhnio2022"
  },
  {
    image: "/images/Panellhnio2021.jpg",
    text: "Πανελλήνιο Πρωτάθλημα 2021",
    id:"Panellhnio2021"
  }
];

const PanellhniaPrwtathlhmataCarousel = () => {
  const ref = React.useRef(StackedCarousel);
  return (
    <div className="card w-100 bg-transparent border-0">
      <div style={{ width: "100%",height:"400px" }}>
        <ResponsiveContainer
          carouselRef={ref}
          render={(width, carouselRef) => {
            return (
              <StackedCarousel
                ref={carouselRef}
                slideComponent={Slide}
                slideWidth={500}
                carouselWidth={width}
                data={data}
                maxVisibleSlide={5}
                disableSwipe
                customScales={[1, 0.85, 0.7, 0.55]}
                transitionTime={450}
                height={400}
              />
            );
          }}
        />
        <Fab
          className="card-button left"
          size="small"
          onClick={() => ref.current?.goBack()}
        >
          <KeyboardArrowLeftIcon style={{ fontSize: 30 }} />
        </Fab>
        <Fab
          className="card-button right"
          size="small"
          onClick={() => ref.current?.goNext()}
        >
          <KeyboardArrowRightIcon style={{ fontSize: 30 }} />
        </Fab>
      </div>
    </div>
  );
};

export default PanellhniaPrwtathlhmataCarousel;
