import  "./Donate.css"

const Donate = ()=>{
    const backgroundImageStyle ={
        position:"absolute",
        width:"100%",
        top:"0px",
        zIndex:"-1"
    }
    const donateBtnStyle ={
        padding:"10px",
        borderRadius:"20px",
        borderColor:"pink",
        backgroundColor:"pink",

    }
    
    const hiddenBtn={
       display:"none"
    }
    return (
    <>
    <div>
        <nav class="navbar navbar-expand-lg navbar-light d-flex flex-row-reverse"  id="menuBar">
            <div class="navbar-nav"  >
                <a class="nav-item nav-link active" id="homeBtn" href="/">Αρχική <span class="sr-only">(current)</span></a>
                <a class="nav-item nav-link" id="iPoreiaMouBtn" href="/IPoreiaMou">Η πορεία μου</a>
                <a class="nav-item nav-link" id="ContactBtn" href="/Contact" >Επικοινωνία</a>
                <a class="nav-item nav-link" id="donateBtn" style={donateBtnStyle}  href="/Donate"> Donate</a>
            </div>
        </nav> 
        
    </div>
    </>
    )
}

export default Donate;