export const roadmapData = [
  {
    id: "SingaporeSmashEvent2024",
    stage: 'Singapore Smash Event 2024',
    details: [
      'Singapore',
    ],
    img:"SingaporeSmash"
  },
  {
    id: 'Finland2023',
    stage: 'European Para Youth Games 2023',
    details: [
      'Lahti, Finland',
    ],
    img:""
  },
  {
    id: "GreekParaOpen2023",
    stage: 'Greek Para Open 2023',
    details: [
      'Argostoli, Greece',
    ],
    img:"GreekParaOpen2023"
  },
  {
    id: "GreekParaOpen2022",
    stage:  'Greek Para Open 2022',
    details: [
      'Argostoli, Greece',
    ],
    img:""
  },
  {
    id: "CzechParaOpen2022",
    stage: 'Czech Para Open 2022',
    details: [
      'Ostrava, Czech',
    ],
    img:"CzechParaOpen2022"
  },
  {
    id: "MontenegrinParaChampionships2022",
    stage: 'Montenegrin Para Championships 2022',
    details: [
      'Montenegrin (Podgoriza) 2022',
    ],
    img:"MontenegrinParaChampionships2022"
  },
];
